<template>
    <div class="modal" v-if="modelValue" :class="{ 'active' : modelValue }">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <div class="modal_info">
                    <h1>Goal Settings</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <Form v-slot="{ errors }" ref="progress-goals-form" class="progress-goals-form">
                    <template v-for="(category, c) in goals" :key="c">
                        <div class="goal_items" v-if="category.has_goal >= 0">
                            <div class="goal_title">
                                <img v-if="category.img" :src="require(`@/assets/images/${category.img}`)" :alt="category.name">
                                {{ category.name }}
                            </div>
                            <div class="scope_area">
                                <label :for="`${category.name}-has-goal`" class="switch_option capsule_btn py-1">
                                    <h5>Goal?</h5>
                                    <input type="checkbox" :id="`${category.name}-has-goal`" :true-value="1" :false-value="0" v-model="category.has_goal" hidden>
                                    <div><span></span></div>
                                </label>
                                <div class="pt-2" v-if="category.has_goal">
                                    <label :for="`${category.name}-client-override`" class="checkbox py-2">
                                        <input type="checkbox" :id="`${category.name}-client-override`" :true-value="1" :false-value="0" v-model="category.has_client_override" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        <p>Client can override goal settings</p>
                                    </label>
                                    <!-- <label :for="`${category.name}-coach-override`" class="checkbox py-2">
                                        <input type="checkbox" :id="`${category.name}-coach-override`" :true-value="1" :false-value="0" v-model="category.has_coach_override" hidden>
                                        <span><i class="fas fa-check"></i></span>
                                        <p>Coach can set specific goals for each client</p>
                                    </label> -->
                                    <div class="setting_wpr" v-if="category.sub_items">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Goal Type</label>
                                                <div class="field_wpr m-0">
                                                    <multiselect v-model="category.goal_type.value" v-bind="category.goal_type"></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-2" v-for="(item_goal, s) in category.sub_items" :key="s">
                                            <h4 class="sub_heading2 mb-0">Goal for {{ item_goal.name }}</h4>
                                            <!-- <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Goal Type</label>
                                                    <div class="field_wpr m-0">
                                                        <multiselect v-model="item_goal.goal_type.value" v-bind="item_goal.goal_type"></multiselect>
                                                    </div>
                                                </div>
                                            </div> -->
                                            <div class="form_grp">
                                                <div class="group_item" v-if="category.goal_type.value == 'Target'">
                                                    <label class="input_label">Target Type</label>
                                                    <div class="field_wpr m-0">
                                                        <multiselect v-model="item_goal.target_type.value" v-bind="item_goal.target_type"></multiselect>
                                                    </div>
                                                </div>
                                                <div class="group_item" v-if="category.goal_type.value == 'Target'">
                                                    <label class="input_label">Target Value</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.target_val }">
                                                        <Field autocomplete="off" type="number" :name="`target-val-${c}${s}`" v-model="item_goal.target_val" :class="{ 'has-error': errors.target_val }" />
                                                        <span class="unit" v-if="category.unit">{{category.unit.value}}</span>
                                                    </div>
                                                    <ErrorMessage :name="`target-val-${c}${s}`" class="text-danger" />
                                                </div>
                                                <div class="group_item" v-if="category.goal_type.value == 'Range'">
                                                    <label class="input_label">High Limit</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.high_limit }">
                                                        <Field autocomplete="off" type="number" :name="`high-limit-${c}${s}`" v-model="item_goal.high_limit" :class="{ 'has-error': errors.high_limit }" />
                                                        <span class="unit" v-if="category.unit">{{category.unit.value}}</span>
                                                    </div>
                                                    <ErrorMessage :name="`high-limit-${c}${s}`" class="text-danger" />
                                                </div>
                                                <div class="group_item" v-if="category.goal_type.value == 'Range'">
                                                    <label class="input_label">Low Limit</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.low_limit }">
                                                        <Field autocomplete="off" type="number" :name="`low-limit-${c}${s}`" v-model="item_goal.low_limit" :class="{ 'has-error': errors.low_limit }" />
                                                        <span class="unit" v-if="category.unit">{{category.unit.value}}</span>
                                                    </div>
                                                    <ErrorMessage :name="`low-limit-${c}${s}`" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="setting_wpr" v-else>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Goal Type</label>
                                                <div class="field_wpr m-0">
                                                    <multiselect v-model="category.goal_type.value" v-bind="category.goal_type"></multiselect>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item" v-if="category.goal_type.value == 'Target'">
                                                <label class="input_label">Target Type</label>
                                                <div class="field_wpr m-0">
                                                    <multiselect v-model="category.target_type.value" v-bind="category.target_type"></multiselect>
                                                </div>
                                            </div>
                                            <div class="group_item" v-if="category.goal_type.value == 'Target'">
                                                <label class="input_label">Target Value</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.target_val }">
                                                    <Field autocomplete="off" type="number" :name="`target-val-${c}`" v-model="category.target_val" :class="{ 'has-error': errors.target_val }" />
                                                    <span class="unit" v-if="category.unit">{{category.unit.value}}</span>
                                                </div>
                                                <ErrorMessage :name="`target-val-${c}`" class="text-danger" />
                                            </div>
                                            <div class="group_item" v-if="category.goal_type.value == 'Range'">
                                                <label class="input_label">High Limit</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.high_limit }">
                                                    <Field autocomplete="off" type="number" :name="`high-limit-${c}`" v-model="category.high_limit" :class="{ 'has-error': errors.high_limit }" />
                                                    <span class="unit" v-if="category.unit">{{category.unit.value}}</span>
                                                </div>
                                                <ErrorMessage :name="`high-limit-${c}`" class="text-danger" />
                                            </div>
                                            <div class="group_item" v-if="category.goal_type.value == 'Range'">
                                                <label class="input_label">Low Limit</label>
                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.low_limit }">
                                                    <Field autocomplete="off" type="number" :name="`low-limit-${c}`" v-model="category.low_limit" :class="{ 'has-error': errors.low_limit }" />
                                                    <span class="unit" v-if="category.unit">{{category.unit.value}}</span>
                                                </div>
                                                <ErrorMessage :name="`low-limit-${c}`" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </Form>
            </div>
            <div class="modal_footer">
                <button type="button" class="btn cancel_btn">Cancel</button>
                <button class="btn save_btn">Save</button>
            </div>
        </div>
    </div>
</template>

<script>
import { Field, Form, ErrorMessage } from 'vee-validate'

export default {
    name: 'Goal Settings',
    data(){
        return{
            goals:[
                {
                    name: 'Water',
                    is_edit: 0,
                    img: 'water.svg',
                    has_show: 0,
                    title: 'Water',
                    subtext: 'How much water did you drink?',
                    unit: {
                        mode: 'single',
                        value: 'oz',
                        options: [
                            { value: 'oz', label: 'Ounce' },
                            { value: 'ml', label: 'Milliliters' }
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 10,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Calories Consumed',
                    is_edit: 0,
                    img: 'calories-consume.svg',
                    has_show: 0,
                    title: 'Calories Consumed',
                    subtext: 'How many calories have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'kcal',
                        options: [
                            { value: 'kcal', label: 'kcal' }
                        ],
                    },
                    value: 0,
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Fruits',
                    is_edit: 0,
                    img: 'fruits.svg',
                    has_show: 0,
                    title: 'Fruits',
                    subtext: 'How much fruits have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'pcs',
                        options: [
                            { value: 'pcs', label: 'Pieces' },
                            { value: 'servings', label: 'Servings' },
                            { value: 'cups', label: 'Cups' },
                            { value: 'gm', label: 'Grams' },
                            { value: 'oz', label: 'Ounce' },
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 10,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Vegetables',
                    is_edit: 0,
                    img: 'vegetables.svg',
                    has_show: 0,
                    title: 'Vegetables',
                    subtext: 'How much vegetables have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'pcs',
                        options: [
                            { value: 'pcs', label: 'Pieces' },
                            { value: 'servings', label: 'Servings' },
                            { value: 'cups', label: 'Cups' },
                            { value: 'gm', label: 'Grams' },
                            { value: 'oz', label: 'Ounce' },
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 10,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Macros',
                    is_edit: 0,
                    img: 'macros.png',
                    has_show: 0,
                    title: 'Macros',
                    subtext: 'How much fat, protein, and carbs have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: '%', label: 'Percentage' },
                            { value: 'gm', label: 'Grams' },
                            { value: 'fists', label: 'Fists' },
                            { value: 'palms', label: 'Palms' },
                            { value: 'thumbs', label: 'Thumbs' },
                        ],
                    },
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    sub_items:[
                        {
                            name: 'Fat',
                            status: 1,
                            value: 0,
                            target_type: {
                                mode: 'single',
                                value: 'Greater',
                                options: [
                                    { value: 'Greater', label: 'Greater Than' },
                                    { value: 'Less', label: 'Less Than' },
                                    { value: 'Equal', label: 'Equal To' },
                                ],
                            },
                            high_limit: 55,
                            low_limit: 35,
                            target_val: 45
                        },
                        {
                            name: 'Protein',
                            status: 1,
                            value: 0,
                            target_type: {
                                mode: 'single',
                                value: 'Greater',
                                options: [
                                    { value: 'Greater', label: 'Greater Than' },
                                    { value: 'Less', label: 'Less Than' },
                                    { value: 'Equal', label: 'Equal To' },
                                ],
                            },
                            high_limit: 55,
                            low_limit: 35,
                            target_val: 45
                        },
                        {
                            name: 'Carbs',
                            status: 1,
                            value: 0,
                            target_type: {
                                mode: 'single',
                                value: 'Greater',
                                options: [
                                    { value: 'Greater', label: 'Greater Than' },
                                    { value: 'Less', label: 'Less Than' },
                                    { value: 'Equal', label: 'Equal To' },
                                ],
                            },
                            high_limit: 55,
                            low_limit: 35,
                            target_val: 45
                        }
                    ],
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                },
                {
                    name: 'Fat',
                    is_edit: 0,
                    img: 'fat.svg',
                    has_show: 0,
                    title: 'Fat',
                    subtext: 'How much fat have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: '%', label: 'Percentage' },
                            { value: 'gm', label: 'Grams' },
                            { value: 'fists', label: 'Fists' },
                            { value: 'palms', label: 'Palms' },
                            { value: 'thumbs', label: 'Thumbs' },
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 10,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Protein',
                    is_edit: 0,
                    img: 'protein.svg',
                    has_show: 0,
                    title: 'Protein',
                    subtext: 'How much protein have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: '%', label: 'Percentage' },
                            { value: 'gm', label: 'Grams' },
                            { value: 'fists', label: 'Fists' },
                            { value: 'palms', label: 'Palms' },
                            { value: 'thumbs', label: 'Thumbs' },
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 10,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Carbohydrates',
                    is_edit: 0,
                    img: 'carbohydrates.svg',
                    has_show: 0,
                    title: 'Carbohydrates',
                    subtext: 'How much carbs have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: '%', label: 'Percentage' },
                            { value: 'gm', label: 'Grams' },
                            { value: 'fists', label: 'Fists' },
                            { value: 'palms', label: 'Palms' },
                            { value: 'thumbs', label: 'Thumbs' },
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 10,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Sugar',
                    is_edit: 0,
                    img: 'sugar.png',
                    has_show: 0,
                    title: 'Sugar',
                    subtext: 'How much sugar have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: 'gm', label: 'Grams' },
                        ],
                    },
                    value: 0,
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Sugar Alcohols',
                    is_edit: 0,
                    img: 'sugar-alcohol.svg',
                    has_show: 0,
                    title: 'Sugar Alcohols',
                    subtext: 'How much sugar alcohol have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: 'gm', label: 'Grams' }
                        ],
                    },
                    value: 0,
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Fiber',
                    is_edit: 0,
                    img: 'fiber.png',
                    has_show: 0,
                    title: 'Fiber',
                    subtext: 'How much fiber have you consumed today?',
                    unit: {
                        mode: 'single',
                        value: 'gm',
                        options: [
                            { value: 'gm', label: 'Grams' }
                        ],
                    },
                    min_val: 0,
                    max_val: 100,
                    value: 0,
                    low_text: 'Low',
                    high_text: 'High',
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                    has_goal: 1,
                    has_client_override: 1,
                    has_coach_override: 0,
                    goal_type: {
                        mode: 'single',
                        value: 'Range',
                        options: [
                            { value: 'Range', label: 'Range' },
                            { value: 'Target', label: 'Specific Target' }
                        ],
                    },
                    target_type: {
                        mode: 'single',
                        value: 'Greater',
                        options: [
                            { value: 'Greater', label: 'Greater Than' },
                            { value: 'Less', label: 'Less Than' },
                            { value: 'Equal', label: 'Equal To' },
                        ],
                    },
                    high_limit: 55,
                    low_limit: 35,
                    target_val: 45
                },
                {
                    name: 'Vitamins',
                    is_edit: 0,
                    img: 'vitamins.png',
                    has_show: 0,
                    title: 'Vitamins',
                    subtext: 'Can you please mark-check all the vitamins you took today?',
                    selected_items: [],
                    variants:[
                        {
                            name: 'Vitamin A',
                            status: true,
                        },
                        {
                            name: 'Vitamin B1',
                            status: true,
                        },
                        {
                            name: 'Vitamin B2',
                            status: true,
                        },
                        {
                            name: 'Vitamin B3',
                            status: true,
                        },
                        {
                            name: 'Vitamin B5',
                            status: true,
                        },
                        {
                            name: 'Vitamin B6',
                            status: true,
                        },
                        {
                            name: 'Vitamin B12',
                            status: true,
                        },
                        {
                            name: 'Vitamin C',
                            status: true,
                        },
                        {
                            name: 'Vitamin D',
                            status: true,
                        },
                        {
                            name: 'Vitamin E',
                            status: true,
                        },
                        {
                            name: 'Vitamin K',
                            status: true,
                        },
                        {
                            name: 'Biotin',
                            status: true,
                        },
                        {
                            name: 'Choline',
                            status: true,
                        },
                        {
                            name: 'Folate',
                            status: true,
                        },
                    ],
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                },
                {
                    name: 'Minerals',
                    is_edit: 0,
                    img: 'minerals.png',
                    has_show: 0,
                    title: 'Minerals',
                    subtext: 'Check the minerals you have consumed today',
                    selected_items: [],
                    variants:[
                        {
                            name: 'Calcium',
                            status: true,
                        },
                        {
                            name: 'Chromium',
                            status: true,
                        },
                        {
                            name: 'Copper',
                            status: true,
                        },
                        {
                            name: 'Lodine',
                            status: true,
                        },
                        {
                            name: 'Iron',
                            status: true,
                        },
                        {
                            name: 'Magnesium',
                            status: true,
                        },
                        {
                            name: 'Manganese',
                            status: true,
                        },
                        {
                            name: 'Molybdenum',
                            status: true,
                        },
                        {
                            name: 'Phosphorus',
                            status: true,
                        },
                        {
                            name: 'Potassium',
                            status: true,
                        },
                        {
                            name: 'Selenium',
                            status: true,
                        },
                        {
                            name: 'Sodium',
                            status: true,
                        },
                        {
                            name: 'Zinc',
                            status: true,
                        },
                    ],
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                },
                {
                    name: 'Supplements',
                    is_edit: 0,
                    img: 'supplement.svg',
                    has_show: 0,
                    title: 'Supplements',
                    subtext: 'Check the supplements you have consumed today',
                    selected_items: [],
                    variants:[
                        {
                            name: 'BCAAs',
                            status: true,
                        },
                        {
                            name: 'Creatine',
                            status: true,
                        },
                        {
                            name: 'Fish Oil',
                            status: true,
                        },
                    ],
                    has_notes: 0,
                    notes: '',
                    has_logo: 0,
                    logo: '',
                },
            ]
        }
    },
    props: {
        modelValue: Boolean,
    },
    emits: ['update:modelValue'],

    components:{
        Field,
        Form,
        ErrorMessage,
    },
    methods:{
        closeModal () {
            const vm = this;
            vm.$emit('update:modelValue', false);
        },
    }
}
</script>

<style scoped>
.goal_items{
    border-radius: 6px;
    background: #fff;
    margin-bottom: 30px;
}
.goal_items .goal_title{
    padding: 15px 20px;
    border-radius: 6px;
    font-size: 15px;
    line-height: 20px;
    font-weight: 500;
    color: #121525;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.goal_items .goal_title img{
    max-height: 25px;
    width: auto;
    padding-right: 10px;
}
.scope_area{
    border-top: 1px solid #e9e9e9;
    padding: 20px;
}
.scope_area .field_wpr{
    background: #fff;
    display: flex;
}
.scope_area .field_wpr .unit{
    background: #fbfbfb;
    border-left: 1px solid #d9d9d9;
    border-radius: 0 6px 6px 0;
    height: 50px;
    min-width: 40px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    line-height: 16px;
    color: #5a5a5a;
    font-weight: 500;
}
.scope_area label.checkbox{
    justify-content: flex-start;
}
</style>